@import url(https://fonts.googleapis.com/css2?family=Montserrat&family=Playfair+Display&family=Poppins&display=swap);
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.selected {
  border-bottom: solid 3px #019fb6;
  transition: -webkit-transform 250ms ease-in-out;
  transition: transform 250ms ease-in-out;
  transition: transform 250ms ease-in-out, -webkit-transform 250ms ease-in-out;
  color: tomato;
}

